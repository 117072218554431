<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import PlanificationsData from "./planifications-data";
import AddPlanification from "./add-planification";

import {
  layoutComputed,
  authComputed,
  planificationsMethods,
  planificationsComputed,
  provincesMethods,
  provincesComputed
} from "@/state/helpers";
/**
 * Planifications component
 */
export default {
  page: {
    title: "Planifications",
    meta: [{ name: "Planifications" }],
  },
  components: {
    Layout,
    PageHeader,
    PlanificationsData,
    AddPlanification,
  },
  mounted() {
    this.retrievePlanifications()/* .then(async ()=>{
      if (this.getProvinces.results)
        this.provincesData = this.getProvinces.results;
      else await this.retrieveProvinces();
    }); */
    this.retrieveProvinces();
    
  },
  data() {
    return {
      title: "titles.planifications.text",
      busy: false,

      loaderDashboard: false,

      planificationsData: [],

      provincesData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...planificationsComputed,
    ...provincesComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.planifications.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...planificationsMethods,
    ...provincesMethods,

    retrievePlanifications() {
      this.busy = true;
      return this.getPlanificationsBy({ limit: 100, populate: 'province,centre' }).then(() => {
        this.busy = false;
      });
    },
    retrieveProvinces() {
      if (this.getProvinces.results) {
        this.provincesData = this.getProvinces.results;
      } else {
      return this.getProvincesBy({ limit: 100 }).then((provinces) => {
        return provinces;
      });
      }
    }, 
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-planification-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-planification-modal");
    },
    add(planification) {
      this.addPlanification(planification)
        .then((planification) => {
          this.makeToast("Add planification", planification.name+" has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add planification", error, "danger")
        });
    },
  },
  watch: {
    getPlanifications(newVal) {
      this.planificationsData = newVal.results;
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addPlanification.text")}}
        </b-button>
        <AddPlanification @addPlanification="add" :provinces="provincesData" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <PlanificationsData v-bind:planifications="planificationsData" :busy="busy" />
      </div>
    </div>
  </Layout>
</template>
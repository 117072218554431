<template>
  <div>
    <b-modal
      id="add-planification-modal"
      ref="add-planification-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.addPlanification.text')"
    >
      <!-- Province -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="province">{{$t("modals.province.text")}}</label>
          <b-form-select
            id="province"
            :options="provincesList"
            v-model="planificationAdd.province"
            class="mb-1"
            :state="err.province ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.province }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Centre -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="centre">{{$t("modals.centre.text")}}</label>
          <b-form-select
            id="centre"
            :options="centresList"
            v-model="planificationAdd.centre"
            class="mb-1"
            :state="err.centre ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.centre }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Date -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="date">{{$t("modals.date.text")}}</label>
          <b-form-datepicker id="date" v-model="planificationAdd.date" class="mb-2"></b-form-datepicker>
          <b-form-invalid-feedback>
            {{ err.date }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{$t("buttons.add.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  centresMethods,
  centresComputed,
} from "@/state/helpers";
export default {
  props: ['provinces'],
  created() {},
  computed: {
    ...centresComputed,
  },
  data() {
    return {
      planificationAdd: {
        centre: null,
        province: null,
        date: null,
      },
      err: {
        centre: null,
        province: null,
        date: null,
      },
      provincesList: [{ value: null, text: this.$t("titles.provinces.text"), disabled: true }],
      centresList: [{ value: null, text: this.$t("titles.centres.text"), disabled: true }]
    };
  },
  watch: {
    provinces(newVal){
      this.provincesList = [
        ...this.provincesList,
        ...newVal.map((obj)=>{
          return {
            value: obj.id, text: obj.name
          }
        })
      ]
    },
    getCentres(newVal){
      this.centresList = [{ value: null, text: this.$t("titles.centres.text"), disabled: true }]
      this.centresList = [
        ...this.centresList,
        ...newVal.results.map(obj=>{
        return {
          value: obj.id, text: obj.name
        }
      })]
    },
    'planificationAdd.province'(newVal){
      this.retrieveCentres(newVal)
    }
  },
  methods: {
    ...centresMethods,
    retrieveCentres(province) {
      return this.getCentresBy({ province: province, limit: 500 }).then((centres) => {
        console.log(centres);
        return centres;
      });
    },
    cancel() {
      this.clear();
    },
    clear() {
      this.planificationAdd = {
        centre: null,
        province: null,
        date: null,
      };
      this.err = {
        centre: null,
        province: null,
        date: null,
      };
    },
    add(ok) {
      if (!this.planificationAdd.centre) this.err.centre = this.$t("msgError.obligField.text");
      else this.err.centre = "";

      if (!this.planificationAdd.province) this.err.province = this.$t("msgError.obligField.text");
      else this.err.province = "";

      if (!this.planificationAdd.date) this.err.date = this.$t("msgError.obligField.text");
      else this.err.date = "";

      if (!this.err.centre || !this.err.province ||!this.err.date) {
        const date = new Date(this.planificationAdd.date).toISOString()
        this.planificationAdd = {
          ...this.planificationAdd,
          date: date
        }
        ok();
        this.$emit("addPlanification", this.planificationAdd);
      }
    },
  },
};
</script>